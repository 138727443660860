import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrWelcomeModal'
})

export default class GtrInstructionModal extends Vue {
  @Prop({
    required: true,
    type: Boolean,
    default: false
  })
  value: boolean | undefined;

  data () {
    return {
      show: false
    }
  }

  emitClose () {
    this.$emit('close')
  }
}
